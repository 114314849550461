import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function BorderlessCard(props) {
    
    const useStyles = makeStyles((theme) => ({
        portada: {
            width: "94%",
            objectFit: "fill",
            overflow: "hidden",
            marginLeft: "3%",
            marginTop: "3%"
        },
        title: {
            color: "#2868a6",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "1rem"
        },
        subtitle: {
            textAlign: "center",
            marginBottom: "2rem"
        },
    }));
    const classes = useStyles();

    return (
        <Grid container justify="center" alignItems="center" item xs={12} className={classes.container}>
            <Grid item xs={12}>
                <img className={classes.portada} src={props.src} alt="portada" />
            </Grid>
            <Grid item xs={12}>
                <Typography  variant="h2" className={classes.title}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography  variant="h3" className={classes.subtitle}>
                    {props.subtitle}
                </Typography>
            </Grid>
        </Grid>
    );
}