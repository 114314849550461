import React from 'react';
import SectionTitle from "./SectionTitle"
import Carousel, { arrowsPlugin, Dots, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../App.css';
import BorderlessCard from "./BorderlessCard"

class Rooms extends React.Component {

    constructor() {
      super()
      this.state = {
        value: 0,
        slides: [
            (<div className="card"><BorderlessCard src="estandar.jpg" title="Estándar" subtitle="Estándar" /></div>),
            (<div className="card"><BorderlessCard src="estandar-vista-mar.jpg" title="Estándar" subtitle="Con vista al mar" /></div>),
            (<div className="card"><BorderlessCard src="doble-estandar.jpg" title="Doble" subtitle="Estándar" /></div>),
            (<div className="card"><BorderlessCard src="doble-vista-mar.jpg" title="Doble" subtitle="Con vista al mar" /></div>),
            (<div className="card"><BorderlessCard src="triple-plus2.jpg" title="Triple" subtitle="Plus" /></div>),
        ]
      }
      this.onchange = this.onchange.bind(this);
    }
  
    onchange(value) {
      this.setState({ value });
    }
  
    render() {
      return (
        <div id="rooms" className="container">
            <br />
            <br />
            <SectionTitle title="Habitaciones" color="39b54a"/>
            <br />
            <br/>
            <Carousel className="carousel"
                plugins={[
                    {
                    resolve: arrowsPlugin,
                    options: {
                        arrowLeft: <IconButton aria-label="delete">
                        <ChevronLeftIcon fontSize="large"/>
                    </IconButton>,
                        arrowRight: <IconButton aria-label="delete">
                        <ChevronRightIcon fontSize="large"/>
                    </IconButton>,
                        addArrowClickHandler: true,
                    }
                    },
                    'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                         numberOfSlides: 3
                        }
                    },
                    
                    
                ]}
                breakpoints={{
                  640: {
                    plugins: [
                     {
                       resolve: slidesToShowPlugin,
                       options: {
                        numberOfSlides: 1
                       }
                     },
                   ]
                  },
                }}
                animationSpeed={1000}
                
                value={this.state.value}
                slides={this.state.slides}
                onChange={this.onchange}
                />
                <Dots value={this.state.value} onChange={this.onchange} number={this.state.slides.length} />
            <br />
            <br />
            <br />
        </div>
      );
    }
  }

  export default Rooms;