import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../App.css';
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function Footer(props) {
    
    const useStyles = makeStyles((theme) => ({
        container: {
            width: "100%",
            padding: "3rem 0rem 3rem 0rem",
            backgroundColor: "#39b54a"
        },
        logo: {
            width: "50%",
            height: "auto"
        },
        label: {
            color: "white"
        },
        alignRight: {
            textAlign:"right"
        }
    }));
    const classes = useStyles();

    return (
        <>
        <div className={classes.container}>
            <Grid container justify="center" alignItems="center" item md={12}>
                <Grid container justify="center" alignItems="center" item md={10}>
                    <Grid item md={5} className="imageFooter">
                        <img src="/logo-nombre.png" className={classes.logo} />
                    </Grid>
                    <Grid item md={3} className="footerAlignment">
                        <Typography  variant="h4" className={classes.label}><strong>Mapa del sitio:</strong></Typography>
                        <AnchorLink  style={{textDecoration: "none"}} href='#nav'>
                            <Typography  variant="h4" className={classes.label}>Inicio</Typography>
                        </AnchorLink>
                        <AnchorLink  style={{textDecoration: "none"}} href='#rooms'>
                            <Typography  variant="h4" className={classes.label}>Habitaciones</Typography>
                        </AnchorLink>
                        <AnchorLink  style={{textDecoration: "none"}} href='#gallery'>
                            <Typography  variant="h4" className={classes.label}>Galería</Typography>
                        </AnchorLink>
                        <AnchorLink  style={{textDecoration: "none"}} href='#services'>
                            <Typography  variant="h4" className={classes.label}>Servicios</Typography>
                        </AnchorLink>
                        <AnchorLink  style={{textDecoration: "none"}} href='#contact'>
                            <Typography  variant="h4" className={classes.label}>Contacto</Typography>
                        </AnchorLink>
                        <AnchorLink  style={{textDecoration: "none"}} href='#about'>
                            <Typography  variant="h4" className={classes.label}>Nosotros</Typography>
                        </AnchorLink>
                    </Grid>
                        <Grid item md={4} className="footerAlignmentRight">
                        <Typography  variant="h4" className={classes.label}><strong>Vísitanos en:</strong></Typography>
                        <Typography  variant="h4" className={classes.label}>Av. del Mar 721, Flamingos, 82149.</Typography>
                        <Typography  variant="h4" className={classes.label}>Mazatlán, Sinaloa.</Typography>
                        <br />
                        <Typography  variant="h4" className={classes.label}><strong>Llámanos</strong></Typography>
                        <Typography  variant="h4" className={classes.label}>+52 669 981 5226</Typography>
                        <Typography  variant="h4" className={classes.label}>+52 669 981 5450</Typography>
                        <Typography  variant="h4" className={classes.label}>+52 669 981 5451</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        </>
    );
}