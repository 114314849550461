import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import '../App.css';

export default function CustomFloatingButtons(props) {
    
    const useStyles = makeStyles(theme => ({
        fab: {
          position: 'fixed',
          bottom: "0.5rem",
          right: "0.5rem",
        },
        mb: {
            marginBottom: "0.5rem"
        },
        whats: {
            backgroundColor: "#39b54a"
        },
        instagram: {
            backgroundColor: "#cb3638"
        },
        facebook: {
            backgroundColor: "#2868a6"
        },
        return: {
            backgroundColor: "#262851"
        },
      }));
    const classes = useStyles();

    return (
        <Grid direction="column" justify="center" alignItems="flex-end" item className={classes.fab}>
            <Grid item className={classes.mb}>
                <a href="https://api.whatsapp.com/send?phone=526692836125" target="_blank">
                    <Fab color="primary" aria-label="add" className={classes.whats}>
                            <WhatsAppIcon fontSize="large"/>
                    </Fab>
                </a>
                </Grid>
                <Grid item className={classes.mb}> 
                    <Fab color="primary" aria-label="add" className={classes.instagram}>
                        <InstagramIcon fontSize="large"/>
                    </Fab>
                </Grid>
                <Grid item className={classes.mb}>
                    <a href="https://www.facebook.com/HotelPlayaVictoriaMazatlan/" target="_blank">
                        <Fab color="primary" aria-label="add" className={classes.facebook}>
                            <FacebookIcon fontSize="large"/>
                        </Fab>
                    </a>
                </Grid>
                <Grid item >
                    <AnchorLink href='#nav'>
                        <Fab color="primary" aria-label="add" className={classes.return}>
                            <KeyboardArrowUpIcon fontSize="large"/>
                        </Fab>
                    </AnchorLink>
                </Grid>
            </Grid>
    );
}