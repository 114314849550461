import { makeStyles } from '@material-ui/core/styles';
import "../App.css";

const useStyles = makeStyles((theme) => ({
  portada: {
    width: "100%",
    height: "90vh",
    backgroundImage: "url('portada.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
}));

export default function Portada() {
  const classes = useStyles();

  return (
    <div className={classes.portada} id="cover"> 
        <div className="coverTextContainer hide">
          <h1 className="coverText">Hotel Playa Victoria ofrece más de 60 habitaciones en el malecón frente al mar, teniendo la hermosa playa de Mazatlán, Sinaloa, donde tu estadía es solo el comienzo de tu inolvidable viaje.</h1>
        </div>
      <div className="reserveButton">
        <a href="https://hotels.cloudbeds.com/reservation/RVKvGf">Reserva ahora</a>
      </div>
    </div>
  );
}