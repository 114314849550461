import React from 'react';
import SectionTitle from "./SectionTitle"
import Carousel, { arrowsPlugin, Dots, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../App.css';

class Gallery extends React.Component {

    constructor() {
      super()
      this.state = {
        value: 0,
        slides: [

            (<img className="gallery" src="Hotel.jpg" />),
            (<img className="gallery" src="Hotel6.jpg" />),
            (<img className="gallery" src="Hotel4.jpg" />),
            (<img className="gallery" src="4.jpg" />),
            (<img className="gallery" src="Hotel3.jpg" />),
            (<img className="gallery" src="Hotel5.jpg" />),
          ],
          thumbnails: [
            (<img className="galleryThumbnail" src="Hotel.jpg" />),
            (<img className="galleryThumbnail" src="Hotel6.jpg" />),
            (<img className="galleryThumbnail" src="Hotel4.jpg" />),
            (<img className="galleryThumbnail" src="4.jpg" />),
            (<img className="galleryThumbnail" src="Hotel3.jpg" />),
            (<img className="galleryThumbnail" src="Hotel5.jpg" />),
        ],
      }
      this.onchange = this.onchange.bind(this);
    }
  
    onchange(value) {
      this.setState({ value });
    }
  
    render() {
      return (
        <div className="container container-gallery" id="gallery">
            <br />
            <br />
            <SectionTitle title="Galería" color="262851"/>
            <br />
            <br />
            <Carousel className="carousel"
                plugins={[
                    {
                    resolve: arrowsPlugin,
                    options: {
                        arrowLeft: <IconButton aria-label="delete">
                        <ChevronLeftIcon fontSize="large"/>
                    </IconButton>,
                        arrowRight: <IconButton aria-label="delete">
                        <ChevronRightIcon fontSize="large"/>
                    </IconButton>,
                        addArrowClickHandler: true,
                    }
                    },
                    'infinite',
                    {
                        resolve: autoplayPlugin,
                        options: {
                          interval: 3000,
                        }
                    },
                    
                ]}
                animationSpeed={1000}
                
                value={this.state.value}
                slides={this.state.slides}
                onChange={this.onchange}
                />
                <Dots className="hide" thumbnails={this.state.thumbnails} value={this.state.value} onChange={this.onchange} number={this.state.slides.length} />
            <br />
            <br />
            <br />
        </div>
      );
    }
  }

  export default Gallery;