import { makeStyles, withStyles } from '@material-ui/core/styles';
import SectionTitle from "./SectionTitle"
import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../App.css';

export default function AboutUs(props) {
    
    const useStyles = makeStyles((theme) => ({
        galleryImage: {
            width: "auto",
            height: "60vh",
            objectFit: "cover",
            overflow: "hidden",
        },
        container: {
            backgroundColor: "#F2F2F2",
            position: "relative"
        },
        carousel: {
            marginLeft: "5%",
            marginRight: "5%",
            position: "relative",
        }
    }));
    const classes = useStyles();

    return (
        <>
        <div className={classes.container} id="about">
            <br />
            <br />
            <SectionTitle title="Nosotros" color="39b54a"/>
            <br />
            <br />
            <Carousel className={classes.carousel}
                plugins={[
                    {
                    resolve: arrowsPlugin,
                    options: {
                        arrowLeft: <IconButton aria-label="delete">
                        <ChevronLeftIcon fontSize="large"/>
                    </IconButton>,
                        arrowRight: <IconButton aria-label="delete">
                        <ChevronRightIcon fontSize="large"/>
                    </IconButton>,
                        addArrowClickHandler: true,
                    }
                    },
                    'infinite'
                ]}
                
            >
                <img alt="gallery" className="gallery" src="drone2.jpg" />
                <img alt="gallery" className="gallery" src="Hotel2.jpg" />
                <img alt="gallery" className="gallery" src="1.jpg" />
                <img alt="gallery" className="gallery" src="2.jpg" />
                <img alt="gallery" className="gallery" src="5.jpg" />
                <img alt="gallery" className="gallery" src="6.jpg" />
                <img alt="gallery" className="gallery" src="7.jpg" />
                <img alt="gallery" className="gallery" src="8.jpg" />
                <img alt="gallery" className="gallery" src="9.jpg" />
            </Carousel>
            <br />
            <br />
            <br />
        </div>
        </>
    );
}